import React, { useEffect, useState } from "react";
import img from "./assets/logo-blue-transition.png";
import "./App.css";

function App() {
  const [timeLeft, setTimeLeft] = useState(20);
  useEffect(() => {
    if (timeLeft===0) window.location.replace("https://prizy.co/");

    const intervalId = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div className="wrapper">
      <img src={img} alt="logo" width={200}></img>
      <p>
        Nous sommes heureux d'annoncer que <b>notre marque évolue</b> pour mieux
        refléter notre <b>vision</b>, nos <b>valeurs</b> et{" "}
        <b>qui nous sommes aujourd'hui</b>.<br></br>Nous introduisons
        progressivement notre nouvelle image à travers tous nos canaux de
        communication. Nous sommes ravis de vous présenter le nouveau look
        de notre <a href="https://prizy.co/" className="link"><b>site web</b></a> !
      </p>
      <div className="timerwrapper">
        <p>
          Vous y serez redirigés dans : <b className="timer">{timeLeft} s</b>
        </p>

        <div className="arrow">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default App;
